<template>
  <div id="offer-informations-light">
    <app-sidebar-form
      form-name="offer-informations-light"
      :edition="confirmationEdition"
      :title="$t('trip.hangar.confirmation')"
      :submit-btn-text="$t('trip.shop.add_to_cart')"
      :show-reset="false"
      no-pristine
      @update:edition="$emit('update:edition', $event)"
      @update:entity="submit()"
    >
      <template #fields="data">
        <b-row>
          <b-col cols="12">
            <ul class="list-unstyled">
              <li class="mb-1">{{ $t('trip.event.etd') }} : {{ $moment(etd).utc().format('ddd, MMM Do YYYY HH:mm') }}</li>
              <li class="mb-1">{{ $t('trip.hangar.from') }} : {{ departureFrom.name }}</li>
              <li class="mb-1">{{ $t('trip.event.eta') }} : {{ $moment(eta).utc().format('ddd, MMM Do YYYY HH:mm') }}</li>
              <li class="mb-1">{{ $t('trip.hangar.from') }} : {{ arrivalFrom.name }}</li>
            </ul>
            <br />
            <ul class="list-unstyled">
              <li class="mb-1">
                <font-awesome-icon icon="map-marked-alt" />
                {{ trip.name }}
              </li>
              <li class="mb-1">
                <feather-icon icon="CalendarIcon" />
                {{ $moment(time).utc().format('ddd, MMM Do YYYY HH:mm') }}
              </li>
              <li class="mb-1">
                <font-awesome-icon icon="plane" />
                {{ aircraft.model }}
              </li>
            </ul>
            <br />
            <h5 class="font-weight-bold mb-1">{{ $t('trip.hangar.time_of_stay') }}</h5>
            <ul class="list-unstyled mb-2">
              <li class="mb-1">
                {{ $t('common.start_at') }} : {{ $moment.utc(`${date.start.date} ${date.start.time}`).format('ddd, MMM Do YYYY [at] HH:mm [UTC]') }}
                <span v-if="tripStepEvent && tripStepEvent.airport">
                  ({{ $t('common.local_time') }} :
                  {{
                    $moment
                      .utc(`${date.start.date} ${date.start.time}`)
                      .utcOffset(tripStepEvent.airport.timeZone)
                      .format('ddd, MMM Do YYYY HH:mm')
                  }})
                </span>
              </li>
              <li class="mb-1">
                {{ $t('common.end_at') }} : {{ $moment.utc(`${date.end.date} ${date.end.time}`).format('ddd, MMM Do YYYY [at] HH:mm [UTC]') }}
                <span v-if="tripStepEvent && tripStepEvent.airport">
                  ({{ $t('common.local_time') }} :
                  {{
                    $moment
                      .utc(`${date.end.date} ${date.end.time}`)
                      .utcOffset(tripStepEvent.airport.timeZone)
                      .format('ddd, MMM Do YYYY HH:mm')
                  }})
                </span>
              </li>
              <li v-if="durationCalculated() === 1"> {{ durationCalculated() }} {{ $t('trip.hangar.day') }} </li>
              <li v-else> {{ durationCalculated() }} {{ $t('trip.hangar.days') }} </li>
            </ul>
            <br />
            <!-- internalDescription -->
            <validation-provider #default="{ errors }" :name="$t('trip.hangar.additional_informations')">
              <b-form-group :label="$t('trip.hangar.additional_informations')" label-for="additionnal-info">
                <b-overlay>
                  <b-form-textarea id="additionnal-info" v-model="addInformations" :placeholder="$t('trip.hangar.additional_informations')" rows="4" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </b-overlay>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>
      </template>
    </app-sidebar-form>
  </div>
</template>

<script>
import AppSidebarForm from '@/components/AppSidebarForm.vue'

import { fetchInfoForHangarRequest } from '@/request/globalApi/requests/apiRequests'
import { patchCartHangarRequest } from '@/request/globalApi/requests/tripStepEventRequests'

export default {
  name: 'HangarConfirmation',

  components: {
    AppSidebarForm,
  },
  props: {
    confirmationEdition: {
      type: Boolean,
      required: true,
    },
    offerEditionMode: {
      type: String,
      default: 'show',
    },
    selectedProduct: {
      type: Object,
      default: () => ({}),
    },
    date: {
      type: Object,
      default: () => ({}),
    },
    tripStepEvent: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      iconUploaded: true,
      tripData: {},
      trip: {},
      time: '',
      aircraft: {},
      eventType: '',
      etd: '',
      eta: '',
      departureFrom: '',
      arrivalFrom: '',
      addInformations: '',
    }
  },
  mounted() {
    this.APIFetchInfoForHangar()
  },
  methods: {
    durationCalculated() {
      const startDate = this.$moment.duration(this.$moment.utc(`${this.date.start.date} ${this.date.start.time}`))
      const endDate = this.$moment.duration(this.$moment.utc(`${this.date.end.date} ${this.date.end.time}`))

      return (endDate.subtract(startDate).days() + 1)
    },
    APIFetchInfoForHangar() {
      this.loadProducts = true
      fetchInfoForHangarRequest(this.$route.params.event_id).then(r => {
        const { eventType } = r.data
        this.eventType = eventType

        const { arrivalFromAirport, departureFromAirport } = r.data
        this.departureFrom = departureFromAirport
        this.arrivalFrom = arrivalFromAirport
        const { eta, etd } = r.data
        this.eta = eta
        this.etd = etd

        if (this.eventType === 'TripFlightDeparture') {
          this.trip = departureFromAirport
          this.time = etd
        } else {
          this.trip = arrivalFromAirport
          this.time = eta
        }

        const { organizationAircraft } = r.data
        this.aircraft = organizationAircraft
      })
    },
    submit() {
      const paramData = {
        startAt: this.$moment.utc(`${this.date.start.date} ${this.date.start.time}`).format('YYYY-MM-DD HH:mm'),
        endAt: this.$moment.utc(`${this.date.end.date} ${this.date.end.time}`).format('YYYY-MM-DD HH:mm'),
        comment: this.addInformations,
        variantId: this.selectedProduct.mainVariant.id,
      }

      patchCartHangarRequest(this.$route.params.event_id, paramData)
        .then(() => {
          this.$emit('update:offer')
        })
        .catch(error => {
          this.toaster(error.response.data.message, 'danger', 'AlertTriangleIcon')
        })
    },
  },
}
</script>

<style lang="scss">
#hangarTimeForm {
  padding-right: 0;
  padding-left: 0;
}
</style>
